import React from 'react';
import Spinner from "components/Spinner"
import loadable from '@loadable/component';

const LoadableHepaFilter = loadable(() => import('components/HepaFilterCertificationPage'), {
  fallback: (
    <Spinner />
  ),
});
const HepaFilterPage = () => {
  return <LoadableHepaFilter />;
};
export default HepaFilterPage;
